.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip a:hover {
  color: @primary-1;
}

.@{class-prefix}-card-contact{
  overflow-y: auto;
  overflow-x: hidden !important;
  height: calc(100vh - 150px);
  width: 100%;
}

.@{class-prefix}-card-chat{
  height: calc(100vh - 150px);
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.@{class-prefix}-chat-container{
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0px;
    background-color: transparent;
  
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
  
  }
}

.@{class-prefix}-bubble-chat{
  display: inline-block;
}

.@{class-prefix}-dashboard-card{
  border: none;
  cursor: pointer;

  .icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 4px;
    margin-right: 12px;
  }
  &:hover{
    .icon svg{
      transform: scale(1.1);
    }
  }
}
