.ant-checkbox-inner {
  border-radius: 4px;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  border-radius: 2px;
}

.ant-checkbox-inner::after {
  top: 47%;
  left: 19%;
}

.@{class-prefix}-radio-group {
  overflow-x: auto;
  overflow-y: hidden;
  border: none;
  box-shadow: none;

  &.@{class-prefix}-radio-group-wrap{
    white-space: nowrap;
  } 

  .ant-radio-button-wrapper {
    border-radius: 8px;
    border-left-width: 1.02px;
    &:focus-within{
      box-shadow: none !important;
    }
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    content: unset !important;
  }
}
